<template>
  <div class="text-center base-header full-width mb-4">
    <div class="container-fluid container-lg">
      <div
        class="row g-3"
        :class="{
          'row-cols-md-2 row-cols-lg-2 row-cols-xl-4': isLeader,
          'row-cols-md-1 row-cols-xl-3': !isLeader,
        }"
      >
        <div class="col-lg">
          <router-link :to="{ name: 'explorer' }" class="card-link">
            <DashboardCard>
              <template #icon="iconProps">
                <slot name="icon1">
                  <IconExplorer class="img-size" :color="iconProps.color" />
                </slot>
              </template>
              <template #heading> Explorer </template>
              <div v-if="isLeader">
                <slot name="description1">
                  Explore any US geography to identify top sales targets
                </slot>
              </div>
              <div v-else>
                <slot name="description1">
                  Explore your geography to identify top sales targets
                </slot>
              </div>
            </DashboardCard>
          </router-link>
        </div>
        <div v-if="user.user_type === 'leader'" class="col-lg">
          <router-link :to="{ name: 'region_manager' }" class="card-link">
            <DashboardCard>
              <template #icon="iconProps">
                <slot name="icon2">
                  <IconMgmttools class="img-size" :color="iconProps.color" />
                </slot>
              </template>
              <template #heading> Territories </template>
              <slot name="description1">
                Build, assess, and assign geographic markets
              </slot>
            </DashboardCard>
          </router-link>
        </div>
        <div class="col-lg">
          <router-link :to="{ name: 'lists' }" class="card-link">
            <DashboardCard>
              <template #icon="iconProps">
                <slot name="icon3">
                  <IconLists class="img-size" :color="iconProps.color" />
                </slot>
              </template>
              <template #heading> My Lists </template>
              <slot name="description1">
                Tag top targets and get AI-driven RepSignal suggestions
              </slot>
            </DashboardCard>
          </router-link>
        </div>
        <div class="col-lg">
          <router-link :to="{ name: 'search' }" class="card-link">
            <DashboardCard>
              <template #icon="iconProps">
                <slot name="icon4">
                  <IconSearch class="img-size" :color="iconProps.color" />
                </slot>
              </template>
              <template #heading> Search </template>
              <slot name="description1">
                Navigate to a specific physician, hospital, drug, product, CPT
                code, and more.
              </slot>
            </DashboardCard>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useLoggedInUserStore } from "@/store/loggedInUser";
import DashboardCard from "./DashboardCard.vue";
import IconExplorer from "@/components/icons/IconExplorer.vue";
import IconMgmttools from "@/components/icons/IconMgmttools.vue";
import IconLists from "@/components/icons/IconLists.vue";
import IconSearch from "@/components/icons/IconSearch.vue";

export default {
  name: "ContentHeader",
  components: {
    DashboardCard,
    IconExplorer,
    IconMgmttools,
    IconLists,
    IconSearch,
  },
  setup() {
    const user = useLoggedInUserStore();
    return { user };
  },
  computed: {
    isLeader() {
      return this.user.user_type === "leader";
    },
  },
};
</script>

<style scoped>
.base-header h1 {
  color: #434343;
  text-transform: none;
}
.shaddow {
  box-shadow: 0px 6px 7px 0px rgba(20, 20, 20, 0.4);
  border-radius: 50%;
}
.img-size {
  max-width: 65px;
  height: 65px;
}
.card-link {
  display: block;
  color: inherit;
  transition: transform 0.3s, box-shadow 0.3s;
}
.card-link:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
